// JS file with shared logic code

// Load Styles

import "../scss/main.scss";

// Load particles js

import "particles.js";

// Load Bootstrap init
import { initBootstrap } from "./bootstrap.js";

// Loading bootstrap with optional features

initBootstrap({
  tooltip: true,
  popover: true,
  toasts: true,
});

// Particles.js code

if (document.getElementById("particles-js")) {
  particlesJS.load("particles-js", "../assets/particles.json", function () {
    console.log("callback - particles.js config loaded");
  });
}

// Opening navbar overlay logic

const menuElement = document.getElementById("myNav");

document.getElementById("hamburger-menu").addEventListener("click", () => {
  console.log("clicked");
  menuElement.style.height = "100%";
});

document.getElementById("closebtn").addEventListener("click", () => {
  menuElement.style.height = "0%";
});

document
  .getElementsByClassName("nav-links-container")[0]
  .addEventListener("click", () => {
    menuElement.style.height = "0%";
  });

// reCAPTCHA

function recaptchaCallback() {
  document.getElementById("sendEmailBtn").removeAttribute("disabled");
}
window.recaptchaCallback = recaptchaCallback;

// Send email logic

if (document.getElementById("sendEmailBtn")) {
  document.getElementById("sendEmailBtn").addEventListener("click", () => {
    let name = document.getElementById("emailName").value;
    let email = document.getElementById("emailAddress").value;
    let subject = document.getElementById("emailSubject").value;
    let message = document.getElementById("emailMessage").value;

    sendEmail(name, email, subject, message);
  });
}

const contactEndpoint = process.env.CONTACT_EMAIL_ENDPOINT;
function sendEmail(name, email, subject, message) {
  const body = JSON.stringify({
    senderEmail: email,
    senderSubject: subject,
    senderName: name,
    message: message,
  });

  const requestOptions = {
    method: "POST",

    body,
  };

  fetch(contactEndpoint, requestOptions)
    .then((response) => {
      if (!response.ok) throw new Error("Error in fetch");
      return response.json();
    })
    .then((response) => {
      alert("Email sent successfully!");
    })
    .catch((error) => {
      if (error.statusCode == "500") {
        alert("Email sent successfully!");
      }
    })
    .then(() => {
      document.getElementById("sendEmailBtn").innerText = "Email sent!";
    });
}

// Navbar overlay background/text color changing on hover logic

document.addEventListener("DOMContentLoaded", function () {
  const navLinks = document.querySelectorAll(".nav-links-container a");

  navLinks.forEach((link) => {
    link.addEventListener("mouseenter", function () {
      const gradient = this.getAttribute("data-gradient");
      const textColor = this.getAttribute("data-color");

      // Apply the gradient to the overlay background
      document.querySelector("#myNav").style.background = gradient;

      // Apply the text color to all a elements within .nav-links-container
      navLinks.forEach((innerLink) => {
        innerLink.style.color = textColor;
      });
    });

    link.addEventListener("mouseleave", function () {
      document.querySelector("#myNav").style.background = ""; // Reset background
      navLinks.forEach((innerLink) => {
        innerLink.style.color = ""; // Reset text color
      });
    });
  });
});

// Get current year that's displayed in footer

document.addEventListener("DOMContentLoaded", function () {
  const currentYear = new Date().getFullYear();
  document.getElementById("currentYear").textContent = currentYear;
});

// Blur navbar background once a person scrolls down

// Get the navbar element
const navbar = document.querySelector("header");

// Listen for the scroll event on the window
window.addEventListener("scroll", function () {
  // Define the scroll threshold in pixels (e.g., 100 pixels)
  const scrollThreshold = 60;

  // Check if the current scroll position is greater than or equal to the threshold
  if (window.scrollY >= scrollThreshold) {
    // Add the 'scrolled' class
    navbar.classList.add("scrolled");
  } else {
    // Remove the 'scrolled' class
    navbar.classList.remove("scrolled");
  }
});
